var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 ma-0",staticStyle:{"max-width":"100%"},attrs:{"fill-height":"","tag":"section"}},[_c('v-slide-y-transition',{attrs:{"appear":""}},[_c('v-row',{staticClass:"pa-0 ma-0",staticStyle:{"height":"100%"},style:({
                background:
                    this.$vuetify.theme.dark == true ? 'black' : 'lightgray'
            })},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-card',{staticStyle:{"height":"100%"},attrs:{"flat":"","min-width":"100%"}},[_c('v-row',{staticClass:"mx-auto",staticStyle:{"min-height":"90%","max-width":"600px","min-width":"400px","height":"90%"},attrs:{"no-gutters":"","height":"100%"}},[_c('v-col',{staticClass:"pa-10",attrs:{"align-self":"center","align":"center"}},[_c('v-card-text',{staticClass:"text-center",staticStyle:{"width":"100%"},attrs:{"dir":"auto"}},[_c('div',{staticStyle:{"font-size":"60px"}},[_c('v-img',{staticClass:"ma-auto",attrs:{"width":"400","src":require("../../assets/alqamer.png"),"lazy-src":require("../../assets/alqamer.png")}})],1)]),_c('v-form',{staticClass:"pa-10",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('v-card-text',{staticClass:"text-center"},[_c('settings',{staticClass:"mb-3"}),_c('v-text-field',{attrs:{"label":_vm.$t('login.username'),"rules":[
                                            function (value) { return !!value ||
                                                _vm.$t('ThisFieldIsRequired'); }
                                        ],"outlined":"","name":_vm.getId},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{attrs:{"label":_vm.$t('login.password'),"rules":[
                                            function (value) { return !!value ||
                                                _vm.$t('ThisFieldIsRequired'); }
                                        ],"type":_vm.show1 ? 'text' : 'password',"append-icon":_vm.show1
                                                ? 'mdi-eye'
                                                : 'mdi-eye-off',"outlined":"","name":_vm.getId},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-col',{staticClass:"text-center"},[_c('v-btn',{ref:"sendReply",attrs:{"loading":_vm.loading,"type":"submit","large":"","block":"","color":"primary darken-1"},on:{"click":function($event){$event.preventDefault();return _vm.login()}}},[_vm._v(" "+_vm._s(_vm.$t("login.signIn"))+" ")])],1)],1)],1)],1),_c('v-footer',{attrs:{"dark":"","padless":"","absolute":""}},[_c('v-card',{staticClass:"flex",attrs:{"flat":"","tile":""}},[_c('v-card-title',{staticClass:"primary darken-2"},[_c('strong',{staticClass:"subheading"},[_c('a',{attrs:{"text":"","href":"https://kf.iq","target":"_blank"}},[_c('v-img',{staticStyle:{"width":"200px","height":"52px"},attrs:{"width":"200","src":require("../../assets/Kafel-logo.svg"),"lazy-src":require("../../assets/Kafel-logo.svg")}})],1)]),_c('v-spacer'),_vm._l((_vm.icons),function(icon){return _c('v-btn',{key:icon.icon,staticClass:"mx-4",attrs:{"href":icon.link,"target":"_blank","dark":"","icon":""}},[_c('v-icon',{attrs:{"size":"24px"}},[_vm._v(" "+_vm._s(icon.icon)+" ")])],1)})],2)],1)],1)],1)],1),_c('v-col',{staticClass:"hidden-xs-and-up hidden-sm-and-down",attrs:{"cols":"12","md":"6","lg":"6","align-self":"center"}},[_c('v-img',{attrs:{"height":"90%","width":"90%","src":require("../../assets/login1.png")}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }